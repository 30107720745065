const portfolioitem = [
    {
     image: "images/pf1.jpg", 
        title: "Graphics Design",
        discription: " includes the entire process of defining requirements, visualizing and creating graphics including illustrations, logos, layouts and photos",
        catigory: "branding",
    },
    {
        image: "images/pf2.jpg",
        title: "Web Development",
        discription: "Writing well designed, testable, efficient code by using best software development practices",
        catigory: "research",
    },
    {
        image: "images/pf3.jpg",
        title: "App Development",
        discription: " mobile app developer uses programming languages and development skills to create, test, and develop applications on mobile devices. ",
        catigory: "marketing",
    },
    {
        image: "images/pf4.jpg",
        title: "Digital Marketing",
        discription: "the promotion of brands to connect with potential customers using the internet and other forms of digital communication.",
        catigory: "planning",
    },
    {
        image: "images/pf5.jpg",
        title: "SEO Services",
        discription: "SEO services refers to the suite of different techniques and procedures that aim to increase the visibility of a website by optimizing it for search engines.",
        catigory: "branding",
    },
    {
        image: "images/pf6.jpg",
        title: "Product Design",
        discription: "process of imagining, creating, and iterating products that solve users’ problems or address specific needs in a given market.",
        catigory: "marketing",
    },
]


export default portfolioitem;