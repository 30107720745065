import React, { useState } from "react";
import contactlist from "./cantactlist";
import "../css/contact.css";
import Zoom from 'react-reveal/Zoom';
import { useFormik } from "formik";
import contactschema from "./schema";
import axios from 'axios';

const initialValues = {
   name: "",
   email: "",
   phone: "",
   subject: "",
   message: ""
}


const Contact = () => {

   const [thanks, setthanks] = useState(false);
   const [thankspara, setthankspara] = useState();

   const { values, errors, handleBlur, touched, handleChange, handleSubmit } = useFormik({
      initialValues: initialValues,
      validationSchema: contactschema,
      onSubmit: async(values) => {

      let ress = await axios.post("contactform.php", values);
         setthanks(true);
         
         if(ress.data == "thanks"){
            setthankspara(true);
         }else{
            setthankspara(false);
         }
         
      }
      
   })




   return (
      <>
         <div className="contact" id="contact">
            <div className="container">
               <div className="innercontact row">
                  <Zoom>
                     <div className="leftcontact col-lg-4">
                        {
                           contactlist.map((elem) => {
                              return (
                                 <div className="contactboxx">
                                    <div className="contacticon">
                                       <div className="cicons">{elem.image}</div>
                                    </div>
                                    <div className="contactdiscription ms-4">
                                       <h4>{elem.name}</h4>
                                       <p>{elem.fp}</p>
                                       <p>{elem.sp}</p>
                                    </div>
                                 </div>
                              )
                           })
                        }
                     </div>
                  </Zoom>
                  <Zoom>
                     <div className="rightcontact col-lg-8">
                        <div className="innerrightcontact"> 
                           {
                              thanks ?
                                 <div className="text-center">
                                    <h4 className={thankspara == true ? "thanksheading" : "thanksheading text-danger"}>{thankspara == true ? "Thank you for contacting us!" : "something is wrong please try again after some time"}</h4>
                                    <p className="thankspara">{thankspara == true ? "A member from our team will reach out shortly." : "" }</p>
                                 </div>
                                 :
                                 <div className="contactform">
                                    <div className="topcontact">
                                       <span>Get in Touch</span>
                                       <h2 className="mb-2 contacth2">Have a Question About Our Service?</h2>
                                       <p>Send us a message and we will get back to you within 24 hours</p>
                                    </div>
                                    <div className="bottomcontact">
                                       <form onSubmit={handleSubmit}>
                                          <div className="row">
                                             <div className="col-lg-6 mb-4 mb-4">
                                                <input type="text" placeholder="Name" name="name"  autoComplete="off" value={values.name} onChange={handleChange} onBlur={handleBlur} />
                                                {errors.name && touched.name ? <p className="errorpara">*{errors.name}</p> : null}
                                             </div>
                                             <div className="col-lg-6 mb-4 mb-4">
                                                <input type="email" placeholder="Email" name="email"  autoComplete="off" value={values.email} onChange={handleChange} onBlur={handleBlur} />
                                                {errors.email && touched.email ? <p className="errorpara">*{errors.email}</p> : null}
                                             </div>
                                          </div>
                                          <div className="row">
                                             <div className="col-lg-6 mb-4 mb-4">
                                                <input type="number" placeholder="Phone" name="phone"  autoComplete="off" value={values.phone} onChange={handleChange} onBlur={handleBlur} />
                                                {errors.phone && touched.phone ? <p className="errorpara">*{errors.phone}</p> : null}
                                             </div>
                                             <div className="col-lg-6 mb-4 mb-4">
                                                <input type="text" placeholder="Subject" name="subject" autoComplete="off" value={values.subject} onChange={handleChange} onBlur={handleBlur} />
                                                {errors.subject && touched.subject ? <p className="errorpara">*{errors.subject}</p> : null}
                                             </div>
                                          </div>
                                          <div className="row">
                                             <div className="col-lg-12">
                                                <textarea rows={5} placeholder="Type Message" name="message" autoComplete="off" value={values.message} onChange={handleChange} onBlur={handleBlur} />
                                             </div>
                                          </div>
                                          <div className="row mt-4">
                                             <div className="col-lg-12 text-center">
                                                <button className="contactbutton" type="submit">SEND MESSAGE</button>
                                             </div>
                                          </div>
                                       </form>
                                    </div>
                                 </div>
                           }

                        </div>
                     </div>
                  </Zoom>
               </div>
            </div>
         </div>
      </>
   )
}

export default Contact;

