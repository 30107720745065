import React from "react";
import "../css/service.css";
import items from "./carditem"; 
import { useState } from "react";
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';

const Services = () => {
    const [carditem] = useState(items);
    
    return (
        <>
      
            <div className="service" id="service">
                <div className="container">
                    <div className="innerservice">
                    <Zoom>
                        <div className="topservice">
                            <h6>Services</h6>
                            <h2>Our Best Services</h2>
                            <p className="servicepara">The Power Of Full-Stack, Agile Development Teams. Zero Overheads. Embrace Maximum Flexibility And Embrace On-Time Delivery.</p>
                        </div>
                        </Zoom>
                        <div className="row">
                            {
                                carditem.map((element) => {

                                    return (
                                        <Slide bottom>
                                        <div className="col-lg-4 cardmargin col-md-6 col-sm-6 col-12">
                                            <div className="cardsservice">
                                                <div className="cardimage mb-5">
                                                    <i className="serviceitag">
                                                        {element.image}
                                                    </i>
                                                </div>
                                                <div className="cardcontent">
                                                    <h4>{element.title}</h4>
                                                    <p className="servicepara">{element.discription}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>
        
        </>
    )
}

export default Services;