import React from "react";
import "../css/hero.css";
import Zoom from 'react-reveal/Zoom';
import Reveal from 'react-reveal/Reveal';


const Hero = ()=>{ 
    
    return(
        <>
            <div className="hero" id="home">
                <div className="container">
                    <div className="innerhero row align-items-center">
                    
                        <div className="col-lg-6">  
                            <Zoom > 
                            <div>
                                <h1 className="heroh">See your company's ideal future, and we'll make it a reality.</h1>
                                <p className="herop">We make building possible and empower teams to build impossible. This anthem is our commitment to the industry - and our commitment to you.</p>
                                <a href="#contact"><button className="herobutton">GET STARTED</button></a>
                            </div>
                            </Zoom> 
                        </div>
                        
                        <div className="col-lg-6 heroimage">
                        <Reveal effect="fadeInUp"> 
                           <img className="clae" src="images/hero-image.jpg" alt="hero"/>
                        </Reveal>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero;