import React from "react";
import "../css/footer.css";


const Footer = ()=>{
    return(
        <>
            <div className="footer">
                <div className="container">
                    <div className="innerfooter row">
                        <div className="col-lg-4">
                            <div className="mb-4">
                                <h2 className="mb-2"><a href="#home" className="footerlogo"><img src="images/xamsoftmobile.png" alt="icon"/></a></h2>
                                <p>Making the world a better place through constructing elegant hierarchies.</p>
                            </div>
                            <p><span className="d-block text-dark">© 2024 Xamsoft.</span>Designed and Developed by Xamsoft</p>
                        </div>
                        <div className="col-lg-2">
                            <div>
                                <h5>Solutions</h5>
                            </div>
                            <ul className="p-0">
                                <li className="ps-0 pb-2">Marketing</li>
                                <li className="ps-0 pb-2">Analytics</li>
                                <li className="ps-0 pb-2">Commerce</li>
                                <li className="ps-0 pb-2">Insights</li>
                            </ul>
                        </div>
                        <div className="col-lg-2">
                            <div>
                                <h5>Support</h5>
                            </div>
                            <ul className="p-0">
                                <li className="ps-0 pb-2">Pricing</li>
                                <li className="ps-0 pb-2">Documentation</li>
                                <li className="ps-0 pb-2">Guides</li>
                                <li className="ps-0 pb-2">API Status</li>
                            </ul>
                        </div>
                        <div className="col-lg-4">
                            <div className="innermessage">
                                <div className="topmessage">
                                    <h5>Address</h5>
                                    <p>10, Moti Bunglow Main Rd, Yeshwant Colony, Shivaji Nagar, Moti Bunglow, Dewas, Madhya Pradesh 455001</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;