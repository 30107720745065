import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import "../css/mobilemenu.css";
import { BsWhatsapp } from "react-icons/bs"
import { AiOutlineTwitter } from "react-icons/ai";
import { BiLogoFacebook } from "react-icons/bi";
import { AiFillYoutube } from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";
import { sett } from "./navbar";

const valuechange = ()=>{
    sett(false);
  }

const Mobilemenu = (props) => {
 
    return (
        <>
            <div className={props.show ? "mobilemenuleft col-lg-4 col-md-4 col-sm-4" : "mobilemenuleftslide col-lg-4 col-md-4 col-sm-4"}>
                <div className="mobiletop">
                    <div className=" py-2">
                        <AiOutlineMail className="mobileicon" />
                        <span className="mobilesocial ps-2">info@xamsoft.com</span>
                    </div>
                    <div className=" py-2">
                        <BsWhatsapp className="mobileicon" />
                        <span className="mobilesocial ps-2">07272-400805</span>
                    </div>
                    
                </div>
                <div className="mobinavi">
                    <ul className="p-0">
                        <li><a href="#home" onClick={valuechange}>Home</a></li>
                        <li><a href="#about" onClick={valuechange}>About</a></li>
                        <li><a href="#service" onClick={valuechange}>Services</a></li>
                        <li><a href="#contact" className="activemobile" onClick={valuechange}>Contact</a></li>
                    </ul>
                </div>
            </div>
        </>
    )
}


export default Mobilemenu;
