import React, { useState } from "react";
import "../css/about.css"
import Slide from 'react-reveal/Slide';

 
const About = ()=>{
    const [color, setcolor] = useState(1);
    const addclass = (e)=>{
        setcolor(e)
    }
  return(
    <>
        <div className="about" id="about">
           <div className="container">
           <Slide bottom>
                <div className="innerabout">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src="images/about-img1.jpg" alt="about" className="w-100"/>
                        </div>
                        <div className="col-lg-6">
                            <div className="aboutfive">
                                <h6 className="story">our story</h6>
                                <h2 className="experiance">Our team comes with the experience and knowledge</h2>
                                <div className="five-tabs mt-5">
                                    <div className="mb-4">
                                       <div className="buttonmain">
                                            <button className= {color === 1 ? "changedbg aboutbutton " : "aboutbutton " } onClick={()=>(addclass(1))}>Who We Are</button>                                        
                                            <button className= {color === 2 ? "changedbg aboutbutton " : "aboutbutton " } onClick={()=>(addclass(2))}>Our Vision</button>       
                                            <button className= {color === 3 ? "changedbg aboutbutton " : "aboutbutton " } onClick={()=>(addclass(3))}>Our History</button>                                            
                                       </div>
                                    </div>
                                    <div className="aboutparadunamic">
                                         <p className={color === 1 ? "show" : "hide"}>Here at Xamsoft, we are continuously evolving how we work and how we look at marketplace challenges so we can continue to deliver measurable, sustainable results for our clients and our communities.</p>
                                         <p className={color === 2 ? "show" : "hide"}>Every day, we strive hard to be a globally recognized organization focused on delivering best-in-class solutions to our clients. For that, we work with world-class people who don’t only leverage technology - but are pioneers and use innovation, and deep industry insights to solve problems.</p>
                                         <p className={color === 3 ? "show" : "hide"}>Xamsoft technology is an ambitious and futuristic software development company from India. Since 2008, we have built web and mobile applications that have stood the test of time and have successfully resounded with users</p>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                </Slide>
           </div>
        </div>
    </>
  )
}


export default About;