import React from "react";
import "../css/business.css";

import Slide from 'react-reveal/Slide';

const Business = () => {
    return (
        <>
            <div className="business">
                <div className="container">
                    <Slide bottom>
                        <div className="innerbusiness text-center">
                            <div className="topbusiness">
                                <h2 className="mb-4">We love to make perfect solutions for your business</h2>
                                <p>Being the leading IT company, xamsoft is the best source for all your Application development requirements We are passionate about finding better ways to achieve your vision or solve your business problem. We would love to work with you to help you achieve it.</p>
                            </div>
                        </div>
                    </Slide>
                </div>
            </div>
        </>
    )
}

export default Business;