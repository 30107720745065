import React from "react";
import * as yup from "yup";


const contactschema = yup.object({
    name: yup.string().min(2).required("please enter your name"),
    email: yup.string().email().required("please enter your email"),
    phone: yup.number().required("please enter your number"),
    subject: yup.string()
});

export default contactschema;