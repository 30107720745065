import React from "react";
import "../css/mapp.css";




const Mapp = ()=>{
   
    return(
        <>
            <div className="mapp"> 
                <div className="container-fluid p-0">
                    <div className="innermapp">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.522318779112!2d76.046248274685!3d22.96781291837067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3963179a270d494b%3A0xb9e616f57c994b35!2s10%2C%20Moti%20Bunglow%20Main%20Rd%2C%20Yeshwant%20Colony%2C%20Shivaji%20Nagar%2C%20Moti%20Bunglow%2C%20Dewas%2C%20Madhya%20Pradesh%20455001!5e0!3m2!1sen!2sin!4v1692690999972!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="location"></iframe>
                    </div>
                </div>
            </div>
        </>
    )
} 


export default Mapp;
