import React from "react";
import "../css/portfolio.css"; 
import { useState } from "react";
import portfolioitem from "./portitem";
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom'; 
 
const Portfolio = () => {
    const [portitem, setportitem] = useState(portfolioitem);
    const [portlist, setportlist] = useState(1);

    const clickport = (e, cati) => {
        setportlist(e);
    const filterdata = portfolioitem.filter((currelem)=>{
              return currelem.catigory === cati
        })
        setportitem(filterdata);
        if(cati === "aall"){
            setportitem(portfolioitem) 
        }
    }

    return (
        <> 
            <div className="portfolio">
                <div className="container">
                    <div className="innerportfolio">
                    <Zoom>
                        <div className="topport">
                            <h6>Our Portfolio</h6>
                            <h2>Our Recent Works</h2>
                            <p className="portpara">Contact us today and discuss how we can develop a mutually beneficial and long term business relationship!</p>
                        </div>
                        </Zoom>
                        <Zoom>
                        <div className="portnavigation row">
                            <div className="col-lg-12 text-center">
                                <button className={portlist === 1 ? "portactive portbutton " : "portbutton "} onClick={() => { clickport(1, "aall")}}>ALL WORK</button>
                                <button className={portlist === 2 ? "portactive portbutton " : "portbutton "} onClick={() => { clickport(2, "branding") }}>BRANDING</button>
                                <button className={portlist === 3 ? "portactive portbutton " : "portbutton "} onClick={() => { clickport(3, "marketing") }}>MARKETING</button>
                                <button className={portlist === 4 ? "portactive portbutton " : "portbutton "} onClick={() => { clickport(4, "planning") }}>PLANNING</button>
                                <button className={portlist === 5 ? "portactive portbutton " : "portbutton "} onClick={() => { clickport(5, "research") }}>RESEARCH</button>
                            </div>
                        </div>
                        </Zoom>
                        <div className="portitems mt-5">
                            <div className="row">
                                {
                                    portitem.map((elem) => {
                                        return (
                                            <Slide bottom>
                                            <div className="col-lg-4 my-2">
                                                <div className="portimage">
                                                    <img src={elem.image} alt="alternate"/>
                                                </div>
                                                <div className="portcontent pt-2">
                                                     <h4>{elem.title}</h4>
                                                     <p>{elem.discription}</p>
                                                </div>
                                            </div>
                                            </Slide>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio;