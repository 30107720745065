import {MdComputer} from "react-icons/md";
import {LuPalette} from "react-icons/lu";
import {AiOutlineShoppingCart} from "react-icons/ai";
import {BsPhone} from "react-icons/bs";
import {RiSlideshowLine} from "react-icons/ri";


const items = [
    {
        id: 1,
        image: <MdComputer/>,
        title: "Web Development",
        discription: "In the light of the competitive and harsh market. We offer affordable web design pricing. We have a decade of experience in creating Websites. For every form of business ranging from small to big, startups to corporate."
    },
    {
        id: 1,
        image: <LuPalette/>,
        title: "UI & UX Design",
        discription: "We provide top-notch UI/UX development services. Get an eye-catching look and feel for your solutions, increase your user interaction and drive sales."
    },
    {
        id: 1,
        image: <AiOutlineShoppingCart/>,
        title: "eCommerce Development",
        discription: "Our experts have expertise in providing enterprise grade complex eCommerce development solutions. We are capable of adding advanced features based in AR/VR, AI, IoT and Machine learning."
    },
    {
        id: 1,
        image: <BsPhone/>,
        title: "Mobile App Development",
        discription: "With a customer-centric approach, our company pours its expertise to burst with fully functional and high performing apps for enterprise, startups and big brands."
    },
    {
        id: 1,
        image: <RiSlideshowLine/>,
        title: "Social Media Marketing",
        discription: "We have a dedicated team of Digital and Social media marketing experts. We know what works and what doesn't. With each social network there are distinct differences in how to best communicate your message effectively."
    },
  
]

export default items;