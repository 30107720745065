import React, { useEffect, useState } from 'react';
import './App.css';
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from './components/navbar';
import About from './components/about';
import Hero from './components/hero';
import Services from './components/services';
import Portfolio from './components/portfolio';
import Business from './components/business';
import Review from './components/review';
import Contact from './components/contact';
import Mapp from './components/mapp';
import Footer from './components/footer';
import Client from './components/client';
import { motion, useViewportScroll } from "framer-motion";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";




function App() {

  const [loading] = useState(false);
  // useEffect(() => {
  //   setloading(true);
  //   setTimeout(() => {
  //     setloading(false);
  //   }, 2500)

  // }, [])
 

  window.addEventListener('load', (event) => {
    console.log('page is fully loaded');
  });
  const { scrollYProgress } = useViewportScroll();
  /* State for progress */
  const [yProgress, setYProgress] = useState(false);

  /* trigger when scroll is updated */
  useEffect(() => {
    return scrollYProgress.onChange(p => setYProgress(p));
  }, [scrollYProgress]);

  let pop = () => {
    // console.log(window.pageYOffset);
    if (window.pageYOffset > 500) {

    }
  }
  pop();

  return (

    <>
      {
        loading
          ?
          <div className='loadermain'>
            <div className="loading-container">
              <div className="loading"></div>
              <div id="loading-text">loading</div>
            </div>
          </div>
          :

          <>
            <motion.div
              className="progres"
              animate={{ scaleX: yProgress }}
              transition={{ duration: 0.2 }}
            />
            <div className="mainwrapper" >
              <Navbar />
              <Hero />
              <About />
              <Services />
              <Portfolio />
              <Business />
              <Review />
              <Contact />
              <Mapp />
              <Footer />
              <div className={window.pageYOffset > 600 ? 'fixedbutton ' : "fixedbuttonhide"}>
                <dutton><a href='#home'><MdOutlineKeyboardArrowUp /></a></dutton>
              </div>
            </div>
          </>
      }
    </>

  );
}

export default App;




