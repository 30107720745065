import React from "react";
import "../css/review.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import customerreview from "./customerreview"
import { PiCircleFill } from "react-icons/pi";
import { LuQuote } from "react-icons/lu";
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';

const Review = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        initialSlide: 0,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          }
        ]
        }
    return (
        <>
            <div className="review"> 
                <div className="container">
                    <div className="innerreview">
                    <Zoom>
                        <div className="topreview">
                            <h6>Customer Reviews</h6>
                            <h2>Our Testimonials</h2>
                            <p>We build solutions for startups and established businesses.</p>
                        </div>
                    </Zoom>
                    <Slide bottom>
                        <div className="reviewslider mt-5">
                            <Slider {...settings} >
                            {
                                customerreview.map((elem) => {
                                    return (
                                       
                                        <div className="reviewitem ">
                                            <div className="reviewiteminner">
                                                <div className="ritemtop position-relative">
                                                    <p>{elem.discription}</p>
                                                    <LuQuote className="reviewquote" />
                                                </div>
                                                <div className="ritembottom d-flex mt-5 ">
                                                    <div>
                                                        <img src={elem.image} alt="alternate" />
                                                    </div>
                                                    <div className="ms-4">
                                                        <h4>{elem.name}</h4>
                                                        <h5>{elem.skills}</h5>
                                                        
                                                    </div>
                                                </div>
                                                <PiCircleFill className="reviewcircle" />
                                                <PiCircleFill className="reviewcirclebottom" />
                                            </div>

                                        </div>
                                    
                                    )
                                })
                            }

                        </Slider>
                    </div>
                    </Slide>
                </div>
            </div>
        </div >
        </>
    )
}


export default Review;