import React, { useState } from "react";
import "../css/navbar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross1 } from "react-icons/rx"
import Mobilemenu from "./mobilemenu";

let sett = "";
const Navbar = () => {
    
    const [showmedia, setshowmedia] = useState(false);
     sett = (e)=>{
        setshowmedia(e)
    }
    if(showmedia === true){
        document.body.classList.add("bodyafter");
    }else{
        document.body.classList.remove("bodyafter")
    } 

    return (
        <>
            <div className="  navbare end-0 start-0  m-auto">
                <div className="container">
                    <div className="navbarinner row ">
                        <div className="pt-2">
                            <div className="row">
                            <div className="col-lg-6 col-sm-6 col-6 text-start navbarlogo">
                                <h2><a href="#home"><img src="images/xamsoftnavbar.png" alt="logo"/></a></h2>
                            </div>
                            <div className="col-lg-6 col-sm-6 col-6 text-end navbarhide">
                                <ul>
                                    <li><a className="fs-5 " href="#home">Home</a></li>
                                    <li><a className="fs-5 " href="#about">About</a></li>
                                    <li><a className="fs-5 " href="#service">Services</a></li>
                                    <li className=" p-0"><a className="fs-5 activecontact" href="#contact">Contact</a></li>
                                </ul>
                                <div className="hamburger" >
                                    <span onClick={() => { setshowmedia(!showmedia) }}>
                                        {showmedia ? <RxCross1 /> : <GiHamburgerMenu />} 
                                    </span>
                                </div> 
                            </div>
                            </div>
                        </div>
                    </div>
                    <Mobilemenu show={showmedia}/>
                </div>
            </div>
           
        </>
    )
}

export default Navbar;
export {sett}