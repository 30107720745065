const customerreview = [
    {
        discription: "Excellent company, did an amazing job, and he was very courteous in the process. Highly recommend!!",
        image: "images/sneha.webp",
        name: "Sneha",
        skills: "United States",
    },
    {
        discription: "His work was fantastic always committed to give an excellent service and do more than requested. He is very professional and I will recommend it to my friends.",
        image: "images/john.webp",
        name: "John",
        skills: "Canada",
    },
    {
        discription: "Excellent work very committed to the project excellent attitude and delivered everything in due time. Very highly recommended",
        image: "images/ariel.webp",
        name: "Ariel M",
        skills: "Argentina",
    },
    {
        discription: "Again very nice work great actitud to work. amazing Job on time!",
        image: "images/firas.webp",
        name: "Firas",
        skills: "United Arab Emirates",
    },
    {
        discription: "He went above and beyond to complete my project and was very quick to accomplish all the work. Great job!! He is highly recommended.",
        image: "images/kuldeep.webp",
        name: "Kuldeep",
        skills: "India",
    },
]

export default customerreview;