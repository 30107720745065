import {BsTelephone} from "react-icons/bs";
import {SlLocationPin} from "react-icons/sl";
import {BsAlarm} from "react-icons/bs";



const contactlist = [
    {
        image: <BsTelephone/>,
        name: "Contact",
        fp: "07272-400805",
        sp: "info@xamsoft.com",
    },
    {
        image: <SlLocationPin/>,
        name: "Address",
        fp: "10 Moti Banglow",
        sp: "Dewas M,P",
    },
    {
        image: <BsAlarm/>,
        name: "Schedule",
        fp: "24 Hours / 7 Days Open",
        sp: "Office time: 9:00 AM - 7:00 PM",
    },
]

export default contactlist;